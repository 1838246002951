<template>
  <div class="hierarchy-tab shared-index">
    <el-row class="table-title" type="flex" align="center">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
          <el-tab-pane v-for="tab in tabs" :key="tab.value" :name="tab.value">
            <div slot="label">
              {{ tab.name }}
              <span v-if="activeTab === tab.value">({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="createHierarchy"
        >Thêm</el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          size="small"
          @click="handleChangeTab"
        >Làm mới</el-button>
      </div>
    </el-row>
    <list-hierarchies
      :loading="callingAPI"
      :data="allHierarchies"
      :type="activeTab"
      :place-types="tabs"
      @reload="handleReloadAfterDelete"
      @update="updateHierarchy"
    />
    <el-row type="flex" justify="end" class="my-4">
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :total="total"
        background
        layout="total, prev, pager, next"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <update-hierarchy-popup
      v-if="visibleUpdate"
      :hierarchy="selectedHierarchy"
      :visible="visibleUpdate"
      :type="activeTab"
      :place-types="tabs"
      :place="place"
      @close="visibleUpdate = false"
      @reload="loadData"
    ></update-hierarchy-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getHierarchy } from '@/services/hierarchy'
import ListHierarchies from './components/List.vue'
import UpdateHierarchyPopup from './components/popup/Update.vue'
import { getPlaceTypes, getTranslation } from '@/utils/filters'

export default {
  name: 'Hierarchy',
  components: {
    ListHierarchies,
    UpdateHierarchyPopup
  },
  props: {
    type: {
      type: [String],
      default: () => null
    },
    place: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      visibleUpdate: false,
      callingAPI: false,
      allHierarchies: [],
      selectedHierarchy: {},
      activeTab: this.type ? this.type : getPlaceTypes()[0].value,
      total: 0,
      limit: 10,
      page: 1,
      searchText: '',
      placeTypes: getPlaceTypes()
    }
  },
  computed: {
    ...mapGetters(['language']),
    tabs() {
      return this.type ? getPlaceTypes(false).filter(t => t.value === this.type) : this.placeTypes
    },
    params() {
      const p = {
        page: this.page,
        per_page: this.limit,
        hierarchy_type: this.activeTab,
        locale: this.language
      }
      if (this.place) {
        return {
          ...p,
          based_id: this.place.id
        }
      }
      return p
    }
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.callingAPI = true
      this.visibleUpdate = false
      getHierarchy(this.params).then((response) => {
        this.allHierarchies = response.result.map((r) => ({
          ...r,
          based_model: r.based_model || {},
          name: getTranslation(r, this.language) ? getTranslation(r, this.language).name : ''
        }))
        this.total = response.total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },
    handleChangePage(page) {
      this.page = page
    },
    createHierarchy() {
      this.selectedHierarchy = null
      this.visibleUpdate = true
    },
    updateHierarchy(data) {
      this.selectedHierarchy = data
      this.visibleUpdate = true
    },
    handleChangeTab() {
      this.page = 1
      this.loadData()
    },
    handleReloadAfterDelete() {
      if (this.allHierarchies.length === 1 && this.page > 1) {
        this.page = this.page - 1
      }
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.hierarchy-tab {
  .table-title {
    &__left {
      max-width: calc(100% - 200px);
      .el-tabs {
        max-width: 100%;
      }
    }
  }
}
</style>
